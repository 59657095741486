<template>
  <div class="has-text-centered mt-big">
    <i class="icon-big primary mdi mdi-information-outline"></i>
    <p class="text-heading bold">Under Development</p>
    <p class="text-subtitle">
      This feature is under development and will be released in the upcoming
      version.
    </p>
    <div class="mt-4">
      <router-link to="/dashboard">
        <b-button> Go to Dashboard</b-button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "UnderDevelopment",
  data() {
    return {};
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
.icon-big {
  font-size: 64px;
}
.mt-big {
  margin-top: 30vh;
}
</style>
